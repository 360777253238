import React, { Component, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { mapData } from './datamapper';

import { SearchProvider } from './components/searchcontext';

import Header from './components/header';

import NavbarResponsive from './components/navbar_responsive';
import Login from './components/login';

import Cards from './components/cards';
import CardCarousel from './components/cardcarousel'

import Dashboard from './components/dashboard';
import ResultGrid from './components/searchResultGrid';


import Merkliste from './components/merkliste';
import Warenkorb from './components/warenkorb';
import NexusSelect from './components/nexusselect';
import OrderHistoryPage from './components/bestellbuch';

import ProductDetails from './components/detailanzeige';
import DetailPage from './components/productdetails';

import Footer from './components/footer';




class App extends Component {

  

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      loggedIn: false,
      nexusSelect: [],
      BuchKram:  [
        { id: 1, 
          titel: 'Agility', 
          untertitel: 'Trainingsaufbau', 
          zusatztext: 'Agility hat sich in den letzten Jahren enorm gewandelt. Auf den heutigen schnellen und technisch anspruchsvollen Parcours muss ein Hund selbstständig arbeiten und nicht nur neben dem Hundeführer laufen. Alexandra Roth - erfolgreiche Agility-Sportlerin, selbstständige Trainerin und Richterin mit internationalem Status - erklärt, was man über den modernen Agility-Sport wissen muss: alle gängigen Ausbildungsformen, vom Anlernen der Geräte und Übungen für Junghunde bis zu den verschiedenen Kommandos und Führtechniken. Zudem werden einzelne Ausbildungsformen miteinander verglichen und Vor- und Nachteile aufgezeigt.', 
          portrait: 'Alexandra Roth war von 2003 bis 2005 Mitglied der Schweizerischen Agility Nationalmannschaft und von 2005 bis 2007 Schweizermeister in dieser Disziplin. Seit 1999 führt sie Ihre eigene Agilityschule.', autor: 'Roth, Alexandra', 
          verlag: 'Müller Rüschlikon Verlag', 
          meldetext: 'lieferbar', 
          isbn: 123321123321223, 
          cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/327/502/1871.jpg', 
          thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/327/502/1871.jpg', 
          zusatzcover: ['https://medien.umbreitkatalog.de/bildzentrale_th/978/327/502/1871.jpg', 'https://medien.umbreitkatalog.de/bildzentrale_th/978/327/502/1871.jpg'], 
          preis: '29,90€', 
          einband:'GEB',  
          art:'TB', 
          meldenummer:'00', 
          artikelnummer:'1', 
          auflage: '5. Auflage', 
          themen: ['Bohnen', ' Fäuste'] , 
          kurztext: 'Lorem Ipsum Bohnum' 
        },
        { id: 2, titel: 'Ju-Jutsu Straßenkampftechniken', untertitel: 'Realistische Technikkombinationen für die Selbstverteidigung', autor: 'Wahle, Stefan', verlag: 'Books on Demand', meldetext: 'vergriffen', isbn: 4565456545665, cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/373/862/7107.jpg', thumb:'https://medien.umbreitkatalog.de/bildzentrale_th/978/373/862/7107.jpg', zusatzcover: [], preis: '19,90€', einband:'GEB', art:'TB', meldenummer:'07', artikelnummer:'2', auflage: '3. Auflage' },
        { id: 3, titel: 'Terence Hill', untertitel: 'Die exklusive Biografie', verlag: 'Riva Verlag', autor: 'Lüdeke, Ulf', meldetext: 'Noch nicht erschienen', isbn: 789870009, thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/386/883/2037.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/386/883/2037.jpg', zusatzcover: [],  preis: '39,90€', einband:'GEB', art:'TB', meldenummer:'04', artikelnummer:'3', auflage: '2. Auflage' },
        { id: 4, titel: 'Buddy haut den Lukas', autor: 'Bud Spencer', untertitel: 'Regie: Michele Lupo, I 1980, FSK ab 6, DVD-Video, Dt, UT: Dt', verlag: 'LEONINE Distribution GmbH', meldetext: 'lieferbar', isbn: 7898788987345, thumb:'https://medien.umbreitkatalog.de/bildzentrale_th/088/765/426/9096.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/088/765/426/9096.jpg', zusatzcover: [], preis: '9,90€', einband:'GEB', art:'TB', meldenummer:'00', artikelnummer:'4', auflage: '2. überarbeitete Auflage' },
        { id: 5, titel: 'Die Edda des Snorri Sturluson', untertitel: '', verlag: 'Reclam', autor: 'Arnulf Krause',  meldetext: 'vergriffen', meldenummer:'07', isbn: 7898788987789, thumb:'https://medien.umbreitkatalog.de/bildzentrale_th/978/315/000/7822.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/315/000/7822.jpg', zusatzcover: [], preis: '29,90€', auflage: ''},
        { id: 6, titel: 'E-Gitarrenbau', untertitel: '', verlag: 'Verlag Martin Koch', autor: 'Koch, Martin', meldetext: 'lieferbar', isbn: 7123788987789, thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/390/131/4155.png', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/390/131/4155.png', zusatzcover: [], preis: '15,90€', einband:'GEB', art:'TB', meldenummer:'00', artikelnummer:'5', auflage: '2. Auflage'},
        { id: 7, titel: 'Phex', untertitel: 'Hilf Dir selbst dann hilft Dir Phex!', autor: 'Michko, Linda de/Demirtel, Eevie', verlag: 'Ulisses Spiele GmbH', isbn: 789878866989, meldetext: 'lieferbar', thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/396/331/1918.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/396/331/1918.jpg', zusatzcover: [], preis: '65,90€', einband:'GEB', art:'TB', meldenummer:'00', artikelnummer:'6', auflage: '1. Auflage'},
     ]
    };
  }
  
  componentDidMount() {
    fetch("/search")
      .then(res => res.json())
      .then(data => {
        const nexusSelect = mapData(data.records)
        this.setState({ nexusSelect }); // Update state with the mapped data
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  componentDidMount() {
    // Check login status on component mount
    const loggedInStatus = localStorage.getItem('loggedIn');
    if (loggedInStatus) {
        this.setState({ loggedIn: true });
    }
  }

  handleLogin = () => {
      this.setState({ loggedIn: true });
      localStorage.setItem('loggedIn', 'true');
  };

  handleLogout = () => {
      this.setState({ loggedIn: false });
      localStorage.removeItem('loggedIn');
  };



render() {

  const { loggedIn } = this.state;
  const { BuchKram } = this.state;
  const { nexusSelect } = this.state;
  

  return (
           <SearchProvider>
                  <Router>
                    <div className="wrapper">

                      <Header 
                        loggedIn={this.state.loggedIn}
                        onLogin={this.handleLogin}
                        onLogout={this.handleLogout}
                      
                      
                      />

                      <NavbarResponsive
                        loggedIn={this.state.loggedIn}
                        
                        onLogout={this.handleLogout}
                      />

                    

                  
                


                      <Routes >
                          <>
                            
                            <Route
                              path="/"
                              element={!loggedIn ? <CardCarousel /> : <p></p>}

                            />


                            <Route
                                path="/dashboard"
                                element={loggedIn ? <Dashboard/> :  <p></p>}
                            
                              />
                      
                            <Route
                                path="/login"
                                element={<Login onLogin={this.handleLogin} />}
                            
                              />

                            <Route 
                              path="/suche" 
                              element={ 
                                  loggedIn ? (
                                    <NexusSelect nexusSelect={nexusSelect} />
                                  ) : (
                                    <p></p>
                                  )
                                }
                            />  

                          

                      
                            
                            <Route 
                              path="/productdetails/:ean"   element={ loggedIn ? <DetailPage /> : <Login />}
                              
                            /> 
                                    

                            <Route
                              path="/merkliste"
                              element={loggedIn ? <Merkliste BuchKram={this.state.BuchKram} /> : <p></p>}
                          
                            />


                            <Route
                              path="/warenkorb"
                              element={loggedIn ? <Warenkorb BuchKram={this.state.BuchKram} /> : <p></p>}
                          
                            />

                            <Route
                              path="/bestellbuch"
                              element={loggedIn ? <OrderHistoryPage/> : <p></p>}
                          
                            />

                            
                          </>
                      



                      </Routes>

                      <Footer />
                    </div>
                  </Router>
           </SearchProvider>    
  );
}
}


export default App;

