import React, {createContext, useState, useContext} from 'react';


export const SearchContext = createContext();

export const SearchProvider = ({children}) => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchParams, setSearchParams] = useState({});
    const [sorting, setSort] = useState({ field: 'rang', order: 'desc' }); // Define and initialize sort state

    return (
        <SearchContext.Provider value={{ searchResults, setSearchResults, searchParams, setSearchParams, sorting, setSort }}>
             {children}
        </SearchContext.Provider>
    );

};

export const useSearch = () => useContext(SearchContext);