import React, { useState, useEffect } from 'react';
import { mapData } from '../datamapper';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
 




const modifyUrl = (originalUrl, modifier) => {
    // Split the URL into parts using "/"
    const parts = originalUrl.split("/");

    // Find the index of the "bilder" segment
    const bilderIndex = parts.findIndex(part => part === "bildzentrale");

    // If "bilder" segment exists
    if (bilderIndex !== -1) {
        // Insert the modifier segment after "bilder"
        parts.splice(bilderIndex + 1, 0, modifier);
    }

    // Join the parts back into a URL string
    return parts.join("/");
};





const DetailPage = () => {

    const { ean } = useParams();
    const [product, setProduct] = useState(null);
    const navigate = useNavigate();
   
    const preisAnzeige = (preis) => {
        return preis.toFixed(2).replace('.',',');
    }

    const formatDate = (erscheinungsdatum) => {
        const year = erscheinungsdatum.substring(0, 4);
        const month = erscheinungsdatum.substring(4, 6);
        const day = erscheinungsdatum.substring(6, 8);
        return `${day}.${month}.${year}`;
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch( `/search?ean=${ean}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch product details');
                }
                const data = await response.json();
                const mappedData = mapData(data); // Map data using your data mapper
                console.log(mappedData);

              //  const dataWithModifiedCoverUrl = mappedData.map(item=> ({
              //      ...item,
              //      thumbnail: modifyUrl(item.cover, "bildzentrale_th") 
              //      thumbnail: `https://medien.umbreitkatalog.de${item.cover.substring(item.cover.indexOf('/bilder') + '/bilder'.length)}`
           
              //  }));

               // setProduct(dataWithModifiedCoverUrl);

                setProduct(mappedData);
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        fetchProduct();
    }, [ean]);

    

    console.log(product); 

    return (    <div>
                    <div className="contentContainer">
                    {product ? (
                            product.map((product) => (            
                                        <div> 
                                                                                                                    
                                            <div class="parentDetailViewGrid">

                                                <div className="gridCover"> 
                                                    <div className="detailCoverContainer">
                                                        {Array.isArray(product.cover) && product.cover.length > 0 ? (
                                                            // Display the first entry in the cover array
                                                            <img className="card-img-top image-height" src={product.cover[0]} alt="" />
                                                        ) : (
                                                            // Display a placeholder image if cover is not an array or is empty
                                                            <img className="card-img-top image-height" src="/assets/img/icons/noImage.png" alt="" />
                                                        )}
                                                    </div>

                                                    <div className="additionalCoverContainer">
                                                          {Array.isArray(product.cover) && product.cover.length > 1 && (  
                                                            <div className="additionalCoverRow" >    

                                                                {product.cover.slice(1).map((zusatzCover, index) => (
                                                                    <div className='additionalCoverPreview'> 
                                                                        <img className='detailCover' src={zusatzCover}  key={index} alt="" />
                                                                    </div>    
                                                              

                                                                ))}
                                                            </div>    
                                                            
                                                        )} 

                                                     </div>

                                                </div>



                                                <div className="gridDetails"> 
                                                    
                                                        <div className="detailBaseInfo">
                                                             <h3 className="detailTitel"><b>{product.titel}</b></h3>
                                                            <br/>          
                                                            <h5>{product.untertitel}</h5>
                                                          
                                                          
                                                            <br/>
                                                            <p>{product.autor}</p>
                                                            <p>{product.verlag}</p>
                                                            <br></br>
                                                            <p>{product.produktgruppe}</p>
                                                            <p>erschienen am {formatDate(product.erscheinungsdatum)}</p>
                                                            <br></br>
                                                            Herausgeber: {product.herausgeber}
                                                            <br />
                                                            Illustrationen: {product.illustration}
                                                           
                                                       
                                                        <p>Themen: {product.themenbez}</p>
                                                        </div>    
                                                   
                                                    
                                                        <div className="detailButtonArea">
                                                            <div className="detailMeldePreis">
                                                                <div className="detailPreisStyle">
                                                                    {preisAnzeige(product.preis)} €
                                                                </div>    
                                                                <div className={product.meldetext}>
                                                                    {product.meldetext}
                                                                </div>    
                                                                <br />
                                                                
                                                            </div>
                                                            <div>    
                                                                <button className="umbButtonIconList"> 
                                                                    <span className="iconListHover"></span>
                                                                </button> 
                                                                <button className="umbButtonIconCart" disabled={product.meldetext==='vergriffen'}> 
                                                                    <span className="iconCartHover"></span>
                                                                </button>
                                                                <br />
                                                                <br />
                                                                <div>
                                                                    <button onClick={() => navigate('/suche')}>Zurück zur Suche</button>
                                                                </div>
                                                            </div>
                                                            <div className="detailBibliographie">
                                                                Artikelnummer: {product.artikelnummer}
                                                                <br />
                                                                ISBN/EAN: {product.isbn}
                                                                <br />
                                                                Sprache: {product.sprache}
                                                                <br />
                                                                Seiten: {product.formatangaben}
                                                                <br />
                                                                Format: {product.format}
                                                                <br />
                                                                Einband: {product.produktform}
                                                             </div>    
                                                        </div>
                                                    
                                                </div>
                                             
                                                <div className="gridText"> 
                                                    <h4>Beschreibung</h4>    
                                                    {product.zusatztext}  
                                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy 
                                                    eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                                                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                                                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                                                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
                                                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   
                                                    Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat 
                                                    nulla facilisis at vero eros et accumsan et iusto  
                                                     <br />
                                                     <br />
                                                     <h4>Autorenportrait</h4>
                                                     Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                                                     sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
                                                     ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna 
                                                     aliquyam erat, sed diam voluptua. 
                                                     At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                                    {product.portrait}
                                                </div>
                                               
                                            </div>          
  
                                     </div>    
                                    ))   
                                ) : (
                                    <p></p>
                                )}

                                   
                    </div>        
                </div>
    );
  };
  
  export default DetailPage;



