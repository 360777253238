import React, { Component, useState, useEffect  } from 'react'; //imrc Enter
import { useNavigate, useParams } from 'react-router-dom';
import { mapData } from '../datamapper';



// class zu className # zu /

// styleS anstelle von style



const NexusSelect = () => {
 
    const [nexusSelect, setNexusSelect] = useState([]);
    const navigate = useNavigate();
   
    const preisAnzeige = (preis) => {
        return preis.toFixed(2).replace('.',',');
    }

    useEffect(() => {
        const fetchNexusSelect = async () => {
            try {
                const apiUrl = `/search?stichwort=&count=5&offset=0&sort=rang&sort_order=desc`;
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch search results');
                }
                const data = await response.json();
                const mappedData = mapData(data);
                setNexusSelect(mappedData); // Assuming the response is an array of search results
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };

        fetchNexusSelect();
    }, []); // Empty dependency array to run the effect only once on component mount




 // wenn nexusSelect.map als "ist keine Funktion" deklariert wird, ist es nicht gefüllt (keine Verbindung zum Webservice) oder die Daten sind nicht gemapped.
    
    return (
        <div className="contentContainer">
            <div className="landingPageText">
                 <br />
                <h3>Zukünftig individueller Begrüßungstext</h3>
                <br />
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            
            </div>

            <br />
            <hr className="hrUmb" />
            <br />

            <div className="headlineUmb">
                <h3>Aktuelle Toptitel</h3>
            </div>

            <div className="cardsGrid"> 
                    {nexusSelect.map((item) => (
                        <div className="card" key={item.id}>
                            <div className="cardImgContainer">
                                
                                {Array.isArray(item.cover) && item.cover.length > 0 ? (
                                    // Display the first entry in the cover array
                                    <img className="card-img-top image-height" src={item.cover[0]} alt="" />
                                ) : (
                                    // Display a placeholder image if cover is not an array or is empty
                                    <img className="card-img-top image-height" src="noImage.jpg" alt="" />
                                )}

                            </div>    
                            <div className="card-body">
                                <div className="cardHeadlineContainer">
                                    <h5 className="card-title">{item.titel}</h5>
                                    <p className="card-text">{item.autor}</p>
                                </div>
                                <div className="cardHeadlineVerlag">    
                                    <p className="card-text">{item.verlag}</p>
                                </div>
                                <div className="cardDetailContainer">   
                                  {preisAnzeige(item.preis)} €
                                </div>     
                            </div>
                        </div>
                    ))} 
            </div>        
    </div>  


    );
};

export default NexusSelect;





