import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';


export const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === 'umbreit-test' && password === 'k4tz3nk4l3nd3r') {
      onLogin();
      navigate('/suche'); 
    } else {
      setError('Ungültiges Passwort oder Anmeldename')
    }
  };



        return (
            <div className ="contentContainer">
                <h2>Anmeldung</h2>
                <br/>
                <div className ="loginFormContainer">
                      <form onSubmit={handleSubmit}>
                        <div>
                            
                            <input 
                              className="suchEingabe"
                              type="text" 
                              id="username" 
                              placeholder="Anmeldename"
                              value={username} 
                              onChange={(e) => setUsername(e.target.value)}/>
                        </div>
                      <br/>
                      <div>
                       
                          <input 
                              type="password" 
                              className="suchEingabe inputStyle"
                              id="loginPasswort" 
                              placeholder="Passwort"
                              value={password} 
                              onChange={(e) => setPassword(e.target.value)}/>
                      </div>
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                        <br />

                          <button  className="umbButton" type="submit">Login</button>
              
                      </form>
                  </div>  
            </div>    
          );
    
};
 
export default Login;




