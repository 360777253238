import React, { useState, useContext, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { mapData } from '../datamapper';
import { useSearch } from './searchcontext';
import '../nexus.css';
import SortButton from './_sortbuttons';




const NexusSelect = () => {
    
    
    const [lieferbar, setLieferbar] = useState('');

    const [nexusSelect, setNexusSelect] = useState([]);
    const navigate = useNavigate();

    
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(0);

    const resultsEndRef = useRef(null);
    const [loadingMore, setLoadingMore] = useState(false);

    const [sort, setSort] = useState({ field: 'rang', order: 'desc' });
    const [isVisible, setIsVisible] = useState(false);

    const [isErweitert, setIsErweitert] = useState(false);

    const [searchParams, setSearchParams ] = useState({
        stichwort: '',
        umbreitnummer: '',
        autor: '',
        titel: '',
        verlag: '',
        thema: '',
        sprache: '',
        produktgruppe: '',
        lieferbar: '',
        sprache: '',
        sort: 'rang',
        sort_order: 'desc'
        }
    );
    const [isLoading, setIsLoading] = useState(false);

  
    const storeSearchParamsInSession = (searchParams) => {
        sessionStorage.setItem('searchParams', JSON.stringify(searchParams));
    };


    const defaultSearchParams ={
        stichwort: '',
        umbreitnummer: '',
        autor: '',
        titel: '',
        verlag: '',
        ean: '',
        thema: '',
        sprache: '',
        produktgruppe: '',
        warengruppe: '',
        lieferbar: '',
        sprache: '',
        sort: 'rang',
        sort_order: 'desc'
    }


    useEffect(() => {
        // Retrieve search parameters from session storage
        const storedSearchParams = sessionStorage.getItem('searchParams');
        if (storedSearchParams) {
            try {
                const parsedSearchParams = JSON.parse(storedSearchParams);
                setSearchParams(parsedSearchParams);
            } catch (error) {
                console.error('Error parsing search params:', error);
            }
        }
    }, []);


    const fetchNexusSelect = (queryParams, count = 20, offset = 0) => {
     
        const apiUrl = `/search?${queryParams}&count=${count}&offset=${offset}`;

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                const nexusSelectData = mapData(data);
                const allResults = offset === 0 ? nexusSelectData : [...nexusSelect, ...nexusSelectData];
                setNexusSelect(allResults);
                setTotalCount(data.totalCount);


                sessionStorage.setItem('nexusSelect', JSON.stringify(allResults)); 
                sessionStorage.setItem('offset', offset);
                sessionStorage.setItem('totalCount', data.totalCount);
                setLoadingMore(false);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            
    };


    const loadMore = () => {
        const newOffset = offset + 20;
        setOffset(newOffset);
        setLoadingMore(true);

        const queryParams = Object.keys(searchParams)
            .filter(key => searchParams[key])
            .map(key => `${key}=${encodeURIComponent(searchParams[key])}`)
            .join('&');

                
        fetchNexusSelect(queryParams, 20, newOffset);
    };


    useEffect(() => {
        const storedSearchParams = sessionStorage.getItem('searchParams');
        const storedOffset = sessionStorage.getItem('offset');
        const storedResults = sessionStorage.getItem('nexusSelect');
        const storedTotalCount = sessionStorage.getItem('totalCount');
        
        if (storedSearchParams) {
            try {
                const parsedParams = JSON.parse(storedSearchParams);
                setSearchParams(parsedParams);


                 // Initialize sort state if it exists in session storage
                 if (parsedParams.sort && parsedParams.sort_order) {
                        setSort({ field: parsedParams.sort, order: parsedParams.sort_order });
                 }
              

                // Construct query string
                const queryParams = Object.keys(parsedParams)
                    .filter(key => parsedParams[key])
                    .map(key => `${key}=${encodeURIComponent(parsedParams[key])}`)
                    .join('&');


                const initialOffset = storedOffset ? parseInt(storedOffset, 10) : 0;
                setOffset(initialOffset);    

                if (storedTotalCount) {
                    setTotalCount(parseInt(storedTotalCount, 10));
                }

               // fetchNexusSelect(queryParams, 20, initialOffset);

               if (storedResults) {
                    setNexusSelect(JSON.parse(storedResults));
                 } else {
                    fetchNexusSelect(queryParams, 20, initialOffset);
                }
            } catch (e) {
                console.error("Error parsing stored search parameters:", e);
            }
        }
    }, []);


    useEffect(() => {
        sessionStorage.setItem('searchParams', JSON.stringify(searchParams));
        sessionStorage.setItem('offset', offset);
    }, [searchParams, offset]);

    useEffect(() => {
        if (loadingMore && resultsEndRef.current) {
            resultsEndRef.current.scrollIntoView({ behavior: 'smooth' });
            setLoadingMore(false);
        }
    }, [nexusSelect, loadingMore]);

   
   
    const handleSearchSessionStorage = () => {
        sessionStorage.setItem('searchParams', JSON.stringify(searchParams));
        
        // Construct query string
        const queryParams = Object.keys(searchParams)
            .filter(key => searchParams[key])
            .map(key => `${key}=${encodeURIComponent(searchParams[key])}`)
            .join('&');

        setOffset(0);
        fetchNexusSelect(queryParams, 20, 0);
    }


   
    const handleResetSearch = () => {
        setSearchParams(defaultSearchParams);
        sessionStorage.removeItem('searchParams');
        setSort({ field: 'rang', order: 'desc' });
        fetchNexusSelect(defaultSearchParams);
        
    }


    

 


    
    const toggleDetailSearchDiv = () => {
        setIsVisible(!isVisible);
        setIsErweitert(!isErweitert);
    }

   
    const handleLieferbarChange = (event) => {
        setLieferbar(event.target.value);
    };




    const preisAnzeige = (preis) => {
        return preis.toFixed(2).replace('.',',');
    }

    

    const handleSort = (field) => {
        const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
        const updatedSort = { field, order: newOrder };

        // Update the sort state
        setSort(updatedSort);

        // Update the search parameters with the new sort order
        const updatedSearchParams = {
            ...searchParams,
            sort: field,
            sort_order: newOrder
        };

        // Store the updated search parameters in session storage
        sessionStorage.setItem('searchParams', JSON.stringify(updatedSearchParams));

        // Construct query string from updated search parameters
        const queryParams = Object.keys(updatedSearchParams)
            .filter(key => updatedSearchParams[key])
            .map(key => `${key}=${encodeURIComponent(updatedSearchParams[key])}`)
            .join('&');

        // Trigger a request to the web service with the updated search parameters
        fetchNexusSelect(queryParams);
    };




    return (
        <div className="contentContainer">
            
            <div className="sucheBibGrid">
                    <div className="sucheBibStichwort umBorderLeft"> 
                            <input className='suchEingabe' type='text' name='sucheTitel' id='sucheTitel' style={{width: '250px'}} placeholder='Suchebegriff'
                                        value={searchParams.stichwort}
                                        onChange={(e) => setSearchParams({ ...searchParams, stichwort: e.target.value })}   
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleSearchSessionStorage();
                                                }
                                            }}
                                       
                            />
                            <br />
                            <br />

                            <input className='suchEingabe' type='text' name='sucheThema' id='sucheThema' style={{width: '250px'}} placeholder='Thema'
                                value={searchParams.thema}
                                onChange={(e) => setSearchParams({ ...searchParams, thema: e.target.value })}  
                                onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleSearchSessionStorage();
                                    }
                                }
                             }
                
                             />                   

                            
                            <br />


                            <div className={`sucheBibErweitert ${isVisible  ? 'visible' : 'hidden'}`}> 
                                                        <input className='suchEingabe inputStyle' type='text' name='sucheAutor' id='sucheAutor' style={{width: '250px'}} placeholder='Autor'
                                                            value={searchParams.autor}
                                                            onChange={(e) => setSearchParams({ ...searchParams, autor: e.target.value })}   
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    handleSearchSessionStorage();
                                                                    }
                                                                }}
                                                        />
                                                        
                                                        <input className='suchEingabe inputStyle' type='text' name='sucheISBN' id='sucheISBN' style={{width: '250px'}} placeholder='ISBN/EAN'
                                                            value={searchParams.ean}
                                                            onChange={(e) => setSearchParams({ ...searchParams, ean: e.target.value })}  
                                                            onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                handleSearchSessionStorage();
                                                                }
                                                            }
                                                        }
                                                        />


                                                        <input className='suchEingabe inputStyle' type='text' name='sucheVerlag' id='sucheVerlag' style={{width: '250px'}} placeholder='Verlag'
                                                            value={searchParams.verlag}
                                                            onChange={(e) => setSearchParams({ ...searchParams, verlag: e.target.value })}
                                                            onKeyDown={(event) => {
                                                            if (event.key === 'Enter') {
                                                                handleSearchSessionStorage();
                                                                }
                                                            }
                                                        }
                                                        />
                                            
                            </div> 

                            <div className="lieferbarCheckbox">           
                                <label for='sucheNurLieferbar'>nur sofort lieferbare Artikel</label>
                                <input type='checkbox' name='sucheNurLieferbar' id='sucheNurLieferbar' value={lieferbar} onChange={handleLieferbarChange}/>
                            </div>                 
                     </div>



                    <div className="sucheBibSuchButton"> 
                                    <Link className="iconNexusSearch umbutton" onClick={handleSearchSessionStorage}>
                                       <span className="nexus-icon-search"></span>
                                       <span className="nexus-text">finden</span>
                                    </Link>

                                    <Link className="iconNexusErweitert" onClick={toggleDetailSearchDiv}>
                                           
                                    {isErweitert ? (                
                                           <>                
                                            <span className="nexus-icon-reduziert"></span>
                                            <span className="nexus-text">reduzieren</span>
                                           </> 
                                           ) : (
                                            <>                
                                            <span className="nexus-icon-erweitert"></span>
                                            <span className="nexus-text">erweitern</span>
                                           </>     

                                           )
                                        }
                                    </Link>
                                                        
                                    <Link className="iconNexusReset" onClick={handleResetSearch}>
                                       <span className="nexus-icon-reset"></span>
                                       <span className="nexus-text">zurücksetzen</span>
                                    </Link>                               
                               
                    </div>

                    <div className="sucheBibGridDropdowns umBorderLeft">
                            <div className="sucheBibProduktGDropdown"> 
                                    <div className="dropDownLabel">Produktgruppe</div>   
                                    <div className="dropDownMenu">
                                        <select name='produktgruppeDropDown' id='produktgruppeDropDown' className="dropdown"
                                            value={searchParams.produktgruppe}
                                            onChange={(e) => setSearchParams({ ...searchParams, produktgruppe: e.target.value })}
                                        >
                                            <option value=''>Alle</option>
                                            <option value='AL'>Buch</option>
                                            <option value='TB'>Taschenbuch</option>
                                            <option value='SB'>Schulbuch</option>
                                            <option value='HB'>Hörbuch</option>
                                            <option value='KA'>Kalender</option>
                                            <option value='NB'>Nonbook</option>
                                            <option value='SP'>Spiele</option>
                                            <option value='MU'>Musik CD</option>
                                        </select>
                                    </div>    
                            </div>
                            
                
                            <div className="sucheBibSpracheDropdown"> 
                                        <div className="dropDownLabel">Sprache</div>    
                                        <div className="dropDownMenu">
                                            <select name='spracheDropDown' id='spracheDropDown' className="dropdown"
                                                value={searchParams.sprache}
                                                onChange={(e) => setSearchParams({ ...searchParams, sprache: e.target.value })}
                                            >
                                                    <option value=''>Alle</option>
                                                    <option value='GER'>Deutsch</option>
                                                    <option value='ENG'>Englisch</option>
                                                    <option value='FRE'>Französisch</option>
                                                    <option value='SPA'>Spanisch</option>
                                            </select>
                                        </div>    
                            </div>
               
                        </div>                          
                                                        
             </div> 

                                         
  

                     
                    <br></br>                             
                

                    <div className='bibHeadMenu'>
                        
                        <ul>
                        
                            <li>{totalCount} Artikel gefunden, sortiert nach:&nbsp; &nbsp; 
                                <SortButton field="rang" currentSort={sort} onClick={handleSort} text="Relevanz" />
                                <SortButton field="preis" currentSort={sort} onClick={handleSort} text="Preis" />
                                <SortButton field="datum" currentSort={sort} onClick={handleSort}  text ="Datum"/>
                            </li>
                 
                        </ul>
                        <br />
                    </div>
                                                                      

                   

                    <hr className='hrUmb'/>

                    <div className='bibResults'>

                        <div className="bibResultsGrid"> 
                       

                        {nexusSelect.map((item) => (
                                    <>
                                
                                            
                                        
                                            <div className="bibResultsBox"  key={item.ean}  styles={{width: '15rem'}}>  
                                                    <div className="bibImgContainer">
                                                        <Link to={`/productdetails/${item.ean}`}>
                                                            
                                                        
                                                        
                                                            {Array.isArray(item.cover) && item.cover.length > 0 ? (
                                                                // Display the first entry in the cover array
                                                                <img styles={{height: '200px'}} src={item.cover[0]} alt="" />
                                                            ) : (
                                                                // Display a placeholder image if cover is not an array or is empty
                                                                <img styles={{height: '200px'}} src="/assets/img/icons/noImage.png" alt="" />
                                                            )}        


                                                        </Link>    
                                                    </div>     
                                                    <div className="card-body">
                                                        <div className='bibResultBoxTAP umBorderLeft'>
                                                            <b>{item.titel}</b><br/>
                                                            
                                                          
                                                           
                                                        </div>  
                                                        
                                             
                                               
                                                        <div className="bibResultBoxVerlag umBorderLeft">
                                                            {item.autor}
                                                            <br/>
                                                            {item.verlag}
                                                            <br/>
                                                        </div> 
                                                        
                                                        <div className='bibResultBoxPreis umBorderLeft'>    
                                                            <br/>
                                                           <b>{preisAnzeige(item.preis)} €</b>
                                                           
                                                        </div>
                                                        

                                                        <div className='bibResultBoxButtons umBorderLeft'>
                                                            <br/>
                                                            <Link to={`/productdetails/${item.ean}`}>
                                                                <button className="umbButtonIconInfo">
                                                                    <span className="iconInfoHover">
                                                                    </span>
                                                                </button>    
                                                            </Link>
                                                                
                                                            <button className="umbButtonIconList"> 
                                                                <span className="iconListHover"></span>
                                                            </button> 
                                                            <button className="umbButtonIconCart" disabled={item.meldetext==='vergriffen'}> 
                                                                <span className="iconCartHover"></span>
                                                            </button> 

                                                                
                                                        </div>
                                                    </div>
                                                    
                                                <hr className='hrUmb'/>   
                                                            
                                            </div>

                                    </>  
                                                                                       

                                 
                                 
     
                            ))}
                     
                            <div ref={resultsEndRef}></div>
                             
                               
                             </div>  
                            <div className="loadMoreButton">
                                    {offset + 20 < totalCount && (
                                        <button className='' onClick={loadMore}>Weitere Suchergebnisse laden</button>
                                    )} 
                           </div>
                           

                    </div>

        </div>
    );
};

export default NexusSelect;


