import React, { useState, useEffect } from 'react';
import {Link , useLocation, useNavigate, NavLink} from 'react-router-dom';
import '../navigation.css';





const NavbarResponsive = ({loggedIn, onLogout}) => {

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const navLinks = document.querySelectorAll('.nav-Link');
        navLinks.forEach(link => {
            const parent = link.parentElement;
            if (link.getAttribute('href') === location.pathname) {
                parent.classList.add('active');
            } else {
                parent.classList.remove('active');
            }
            
         });
    }, [location]);

    const handleLoginClick = () => {
        navigate('/login');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <nav className="navbar navbar-expand-lg pgnav">
          <div className="container-fluid">
            
            <button className="navbar-toggler"  onClick={toggleMenu}> 
              {isOpen ? (
                <img src="/assets/img/icons/openBook_w_32.png" alt="Menu" />
              ) : (
                <img src="/assets/img/icons/closedBook_w_32.png" alt="Menu Icon2" />
              )}
            </button>
            <div className="navContainer">
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
                
                
                                <>
                                    
                                    {loggedIn ? (
                                            <div className="navigationsLeiste">

                                                <ul className="navLeft">
                                                    <li className="nav-item" >
                                                        <NavLink className="nav-link iconNavSearch" to ="/suche" onClick={toggleMenu} activeClassName="active">
                                                            <span className="nav-icon-search"></span>
                                                            <span className="nav-text">Suche</span>
                                                        </NavLink>
                                                    
                                                    </li>


                                                    <li className="nav-item" >
                                                        <NavLink className="nav-link iconNavWK" to ="/warenkorb" onClick={toggleMenu} activeClassName="active">
                                                            <span className="nav-icon-wk"></span>
                                                            <span className="nav-text">Warenkorb</span>
                                                        </NavLink>
                                                    
                                                    </li>


                                                    <li className="nav-item" >
                                                        <NavLink className="nav-link iconNavList" to ="/merkliste" onClick={toggleMenu} activeClassName="active">
                                                            <span className="nav-icon-list"></span>
                                                            <span className="nav-text">Merkliste</span>
                                                        </NavLink>
                                                    
                                                    </li>

                                                    <li className="nav-item" >
                                                        <NavLink className="nav-link iconNavHistory" to ="/bestellbuch" onClick={toggleMenu} activeClassName="active">
                                                            <span className="nav-icon-history"></span>
                                                            <span className="nav-text">Bestellbuch</span>
                                                        </NavLink>
                                                    
                                                    </li>
                                                </ul>



                                                <ul className="navRight">
                                                    <li className="nav-item" >
                                                        <NavLink className="nav-link iconNavLogout" to ="/" onClick={onLogout} activeClassName="active">
                                                            <span className="nav-icon-logout"></span>
                                                            <span className="nav-text">Abmelden</span>
                                                        </NavLink>
                                                    
                                                    </li>
                                                </ul>

                                            </div>

                                                
                                        ) : (
                                            <div className="navigationsLeiste">
                                                <ul className="navRight">
                                                    <li className="nav-item" >
                                                        <NavLink className="nav-link iconNavLogin" to ="/login"  activeClassName="active">
                                                        <span className="nav-icon-login"></span>
                                                        <span className="nav-text">Anmelden</span>
                                                        </NavLink>
                                                    
                                                    </li>
                                                </ul>    


                                                
                                        </div>
                                    )}
                          </>









                    </div>



          </div>
          </div> 
        </nav>
      );

};


 
export default NavbarResponsive;


/*    
    return (
        <nav className="navbar navbar-expand-lg pgnav">
            <div className={`hamburgerNavigation ${isOpen ? 'open' : ''}`}>
                <div className="hambookerIcon" onClick={toggleMenu}>
                    <img src={isOpen ? '/assets/img/icons/openBook.png' : '/assets/img/icons/closedBook.png'} alt="Menu Icon"/>
                </div>
                <div className="navContainer">
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul className={`navbar-nav mr-auto mt-2 mt-lg-0 navigationItems ${isOpen ? 'open' : ''}` } >
                        {loggedIn ? (
                                                <>
                                                    <li className="nav-item" >
                                                        <Link className="nav-link" to ="/suche">
                                                        
                                                                <span className="iconNavSearch"> 
                                                                    <img src="/assets/img/icons/search_32_w.png" className="iconNavSearchHover"></img>
                                                                </span> 
                                                        </Link>
                                                    </li>
                                                    
                                                    
                                                    <li className="nav-item" >
                                                        <Link className="nav-link" to ="/warenkorb">
                                                        
                                                                <span className="iconNavWK"> 
                                                                    <img src="/assets/img/icons/cart_32_w.png" className="iconNavWKHover"></img>
                                                                </span> 
                                                        </Link>
                                                    </li>
                                                
                                                    <li className="nav-item" >
                                                        <Link className="nav-link" to ="/merkliste">
                                                        
                                                                <span className="iconNavList"> 
                                                                    <img src="/assets/img/icons/merkliste_32_w.png" className="iconNavListHover"></img>
                                                                </span> 
                                                        </Link>
                                                    </li>

                                                

                                                    <li className="nav-item" >
                                                        <Link className="nav-link" to ="/wknexusselect">
                                                        
                                                                <span className="iconNavHistory"> 
                                                                    <img src="/assets/img/icons/history_32_w.png" className="iconNavHistoryHover"></img>
                                                                </span> 
                                                        </Link>
                                                    </li>


                                                
                                                

                                                </>
                                            ) : (
                                                <>
                                                <li className="nav-item active">
                                                    <a className="nav-link" href="/">Home</a>
                                                </li>
                                                <li className="nav-item" onClick={onLogin}>
                                                    <Link className="nav-link" to ="/suche">Login</Link>
                                                </li>
                                                <li className="nav-item" >
                                                    
                                                </li>



                                                </>
                                            ) }    

                        </ul>
                    </div>    
                </div>                                
            </div>
        </nav>    
    );
     
};

*/