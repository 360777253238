import React from "react";
import { useParams } from "react-router-dom";

const ProductDetails = ({ BuchKram }) => {
    const { isbn } = useParams();
    const product = BuchKram.find(item => item.isbn === parseInt(isbn, 10));

  
    return (    <div>
                    <div className="contentContainer">
                    {product ? (
                                        
                                        <div> 
                                                                                                                    
                                            <div class="parentDetailViewGrid">
                                                <div className="gridCover"> 
                                                    <div className="detailCoverContainer">
                                                        <img  className='detailCover' src={product.cover} alt="" />
                                                    </div>
                                                    <div className="additionalCoverContainer">
                                                        {product.zusatzcover.map((zusatzcover, index) =>
                                                            <div className="additionalCoverRow" key={index} > 
                                                                <div className='additionalCoverPreview'>
                                                                    <img  className='detailCover' src={zusatzcover} />
                                                                </div>
                                                            </div>
                                                        )}    
                                                     </div>
                                                </div>
                                                <div className="gridDetails"> 
                                                    <div className="detailBaseInfo">        
                                                        <h3><b>{product.titel}</b></h3>
                                                        <br/>          
                                                        <h4>{product.untertitel}</h4>
                                                        <br/>
                                                        <p>{product.autor}</p>
                                                        <p>{product.verlag}</p>
                                                        <br></br>
                                                        <p>erschienen am </p>
                                                        <p>Themen: {product.themen}</p>
                                                        
                                                    </div>
                                                    <div className="detailButtonArea">
                                                        <div className="detailMeldePreis">
                                                            <div className="detailPreisStyle">
                                                                {product.preis}
                                                            </div>    
                                                            <div className={product.meldetext}>
                                                                {product.meldetext}
                                                            </div>    
                                                            <br />
                                                            
                                                        </div>
                                                        <div>    
                                                            <button className="umbButtonIconList"> 
                                                                <span className="iconListHover"></span>
                                                            </button> 
                                                            <button className="umbButtonIconCart" disabled={product.meldetext==='vergriffen'}> 
                                                                <span className="iconCartHover"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="gridBibliographie"> 
                                                    <p>
                                                        Artikelnummer: {product.artikelnummer}
                                                        <br />
                                                        ISBN/EAN: {product.isbn}
                                                        <br />
                                                        Sprache: 
                                                        <br />
                                                        Seiten: 
                                                        <br />
                                                        Format: 
                                                        <br />
                                                        Einband: 
                                                    </p>
                                                </div>
                                                <div className="gridText"> 
                                                    {product.zusatztext}    
                                                     <br />
                                                     <br />
                                                    {product.portrait}
                                                </div>
                                               
                                            </div>          
  
                                     </div>    
                                ) : (
                                    <p>Artikel nicht gefunden</p>
                                )}

                                   
                    </div>        
                </div>
    );
  };
  
  export default ProductDetails;