import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mapData } from '../datamapper';


const OrderHistoryPage = () => {
    const { sqlID, callerID, sessionID} = useParams();
    const [orderHistory, setOrderHistory] = useState([]);

    const mapOrderHistoryData = (data) => {
        
        return data.records.map(order => ({
            orderId: order.kopf_id,
            knr: order.knr,
            datum: order.datum
            //items: order.items.map(item => ({
            //    productId: item.productId,
            //    productName: item.productName,
            //    quantity: item.quantity,
            //    price: item.price
            //}))
        }));
    };


    useEffect (() => {
        const fetchOrderHistory = async () => {
            try {
               //   const apiUrl = `/bestellbuch/${sqlID}/${callerID}/${sessionID}/`
                
              const apiUrl = `/bestellbuch/1001074/0107534/TEST-737-BDAF-4903-92BA-46824BA34D2D/`

               
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error ('failed to fetch data');
                }

                const data = await response.json();

                const bestellbuchMapped = mapOrderHistoryData(data);

                setOrderHistory(bestellbuchMapped);



            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchOrderHistory();
    }, [sqlID, callerID, sessionID]);


    return (
        <div>
            <h1>Bestellbuch</h1>
            <ul>
                {orderHistory.map((order) => (
                    <li key={order.kopf_id}>{order.datum} - {order.knr}
                        {/*
                    
                            <h2>Order {order.orderId} - {order.date}</h2>
                                    <ul>
                                        {order.items.map(item => (
                                            <li key={item.productId}>
                                                {item.productName} - {item.quantity} x ${item.price}
                                            </li>
                                        ))}
                                    </ul>
                        */}
                    </li>    
                ))}
            </ul>
        </div>
    );

} ;

export default OrderHistoryPage;

